import React, { useState, useRef, useEffect } from "react";
import "./App.css";
import HomeScreen from "./HomeScreen";
import Projects from "./Projects";
import hero from "./Photos/TuffHammerPhotos/hero.jpg";
import projectimage from "./Photos/TuffHammerPhotos/projectimage.jpg";
import servicesimage from "./Photos/TuffHammerPhotos/servicesimage.jpg";
import icon from "./Photos/TuffHammerPhotos/icon.png";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useHistory,
} from "react-router-dom";
import { Transition } from "react-transition-group";

import {
  Grid,
  Button,
  Snackbar,
  Image,
  Paper,
  Fade,
  Slide,
  makeStyles,
  Card,
  Collapse,
  Grow,
} from "@material-ui/core";
import ArrowRightAltIcon from "@material-ui/icons/ArrowRightAlt";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import CheckIcon from "@material-ui/icons/Check";
import TopNav from "./TopNav";
const textColorDark = "black";
const textColorLight = "white";

const App = () => {
  const [outline, setOutline] = useState({});

  return (
    <Router>
      <TopNav setOutline={setOutline} outline={outline} />
      <Switch>
        <Route exact path="/">
          <HomeScreen setOutline={setOutline} />
        </Route>
        <Route path="/projects">
          <Projects setOutline={setOutline} />
        </Route>
      </Switch>
    </Router>
  );
};
const Driveway = () => {
  return (
    <svg
      width="802"
      height="802"
      viewBox="0 0 802 802"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="802" height="802" fill="url(#pattern0)" />
      <defs>
        <pattern
          id="pattern0"
          patternContentUnits="objectBoundingBox"
          width="1"
          height="1"
        ></pattern>
        <image id="image0" width="24" height="24" />
      </defs>
    </svg>
  );
};

export default App;
