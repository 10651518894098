import driveway from "./Photos/TuffHammerPhotos/driveway.svg";
import basement from "./Photos/TuffHammerPhotos/basement.svg";
import excavator from "./Photos/TuffHammerPhotos/excavator.svg";
import stairs from "./Photos/TuffHammerPhotos/stairs.svg";
import patio from "./Photos/TuffHammerPhotos/patio.svg";

const driveways = {
  title: "Driveways",
  icon: driveway,
};

const basements = {
  title: "Basements",
  icon: basement,
};

const excavation = {
  title: "Excavation",
  icon: excavator,
};
const stairsObj = {
  title: "Stairs",
  icon: stairs,
};

const patios = {
  title: "Patios",
  icon: patio,
};

const andMore = {
  title: "And More!",
  icon: driveway,
};

const serviceArr = [
  driveways,
  basements,
  excavation,
  stairsObj,
  patios,
  andMore,
];

export const reviewsArr = [
  {
    name: "Jim",
    project: "Driveway",
    review:
      "TuffHammer did such a great job and were quick and efficient. My driveway is so smooth! I would definitely recommend to anyone looking to have a new driveway.",
  },
  {
    name: "Sarah",
    project: "Walkway",
    review:
      "TuffHammer did a wonderful job building my walkway. Easy to work with and answered all my questions. A+ company.",
  },
  {
    name: "Timothy",
    project: "Patio",
    review:
      "Hire TuffHammer for your concrete needs. Communication was great and performance was golden. I couldn't be more happy with my new patio! Call TuffHammer today!",
  },
];
export { serviceArr };
