import React, { useState, Fragment } from "react";
import {
  Drawer,
  Button,
  MenuItem,
  List,
  ListItem,
  Popover,
  InputLabel,
  Select,
  TextField,
  Grid,
  Paper,
  useMediaQuery,
} from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";

import { SuccessIndicator } from "./JobPaper";

export const QuoteModal = ({ quoteModalVisible, setQuoteModalVisible }) => {
  const theme = useTheme();
  const smallScreen = useMediaQuery(theme.breakpoints.down("md"), {
    defaultMatches: true,
  });
  const [dataSent, setDataSent] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    phone: "",
    email: "",
    message: "",
  });

  ///////////Form submission functions////////////////////////////////////////////////////

  const encode = (data) => {
    return Object.keys(data)
      .map(
        (key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key])
      )
      .join("&");
  };
  async function handleSubmit(e) {
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({ "form-name": "contact", ...formData }),
    })
      .then(() => console.log("Success"))
      .catch((error) => alert(error));
    e.preventDefault();
  }
  /////////////////////////////////////////////////////////////////////////////////////////
  function emailIsValid(email) {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  }
  function phoneNumIsValid(phoneNum) {
    return /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im.test(
      phoneNum
    );
  }

  function updateFormData(e) {
    console.log(formData);
    console.log(e.target.name + "what looking at");
    const field = e.target.name; //get the fileds name so it can be udated in the state(makes it dynamic)
    setFormData((prev) => {
      return {
        ...prev,
        [field]: e.target.value,
      };
    });
  }
  function submitData(e) {
    if (dataSent) return; //so they can't keep pushing the div to send data
    if (formData.email) {
      console.log("email entered");
      const validEmail = emailIsValid(formData.email);
      if (!validEmail) return console.log("not valid email");
    }
    if (formData.phone) {
      console.log("phone entered");
      const validPhone = phoneNumIsValid(formData.phone);
      if (!validPhone) return console.log("not valid phone");
    }
    setDataSent(true);
    handleSubmit(e);
    setTimeout(() => {
      setQuoteModalVisible(false);
      setDataSent(false);
    }, 1500);
  }

  return (
    <Popover
      //  id={id}
      open={quoteModalVisible}
      anchorEl={quoteModalVisible}
      onClose={() => setQuoteModalVisible(false)}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      style={{ zIndex: 100 }}
    >
      <Grid container style={{ padding: 32 }}>
        {dataSent ? (
          <SuccessIndicator />
        ) : (
          <Fragment>
            <Grid item container xs={12} justifyContent="flex-end">
              <Grid
                item
                onClick={() => setQuoteModalVisible(false)}
                style={{ cursor: "pointer" }}
              >
                X
              </Grid>
            </Grid>
            <Grid
              item
              container
              xs={12}
              direction="column"
              style={{ marginTop: 32 }}
            >
              <TextField
                onChange={updateFormData}
                label="Name"
                name="name"
                //  value={formData.name}
                variant="filled"
              />
              <TextField
                label="Phone"
                name="phone"
                onChange={updateFormData}
                //  value={formData.phone}
                variant="filled"
              />
              <TextField
                label="Email"
                name="email"
                onChange={updateFormData}
                // value={formData.email}
                variant="filled"
              />
            </Grid>
            <Grid item container xs={12} style={{ marginTop: 24 }}>
              <InputLabel id="demo-simple-select-helper-label">
                Type of Job?
              </InputLabel>
              <Grid item container xs={12}>
                <Select
                  labelId="demo-simple-select-helper-label"
                  id="demo-simple-select-helper"
                  //  value={quote}
                  name="message"
                  label="Select a Position"
                  onChange={updateFormData}
                  style={{ width: "100%" }}
                >
                  <MenuItem value={"Driveway"} name="message">
                    Driveway
                  </MenuItem>
                  <MenuItem value={"Basement"} name="message">
                    Basement
                  </MenuItem>
                  <MenuItem value={"Excavation"} name="message">
                    Excavation
                  </MenuItem>
                  <MenuItem value={"Stairs"} name="message">
                    Stairs
                  </MenuItem>
                  <MenuItem value={"Patios"} name="message">
                    Patios
                  </MenuItem>
                  <MenuItem value={"Other"} name="message">
                    Other
                  </MenuItem>
                </Select>
              </Grid>
              <Grid
                item
                container
                xs={12}
                justifyContent={smallScreen ? "center" : "flex-end"}
                style={{ marginTop: 32 }}
              >
                <Grid item container xs={7} md={5} onClick={submitData}>
                  <Paper
                    style={{
                      padding: 8,
                      cursor: "pointer",
                      display: "flex",
                      flexDirection: "row",
                      width: "100%",
                      alignItems: "center",
                      backgroundColor: "#E8183A",
                    }}
                    onClick={handleSubmit}
                    elevation={4}
                  >
                    <Grid xs={8} item container justifyContent="center">
                      <p className="cta-text">Get Quote </p>
                    </Grid>

                    <Grid
                      item
                      container
                      xs={3}
                      justifyContent="center"
                      style={{}}
                    >
                      <SvgRightBtn />
                    </Grid>
                  </Paper>
                </Grid>
              </Grid>
            </Grid>
          </Fragment>
        )}
      </Grid>
    </Popover>
  );
};

const SvgRightBtn = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.17 13L15.59 15.59L17 17L22 12L17 7L15.59 8.41L18.17 11H2V13H18.17Z"
        fill="white"
      />
    </svg>
  );
};

export default QuoteModal;
