import React, { useState } from "react";
import {
  Grid,
  Button,
  Snackbar,
  Image,
  Paper,
  Fade,
  Slide,
  makeStyles,
  Card,
  Collapse,
  Grow,
  Avatar,
  TextField,
  useMediaQuery,
  InputLabel,
  Select,
  FormHelperText,
  MenuItem,
} from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import { Fragment } from "react";

const paperPadding = 32;

const JobPaper = ({ smallScreen }) => {
  const [page, setPage] = useState(0);
  const [dataSent, setDataSent] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    phone: "",
    email: "",
    resume: "",
    yoe: "",
    job: "",
  });
  const handleChange = (e) => {
    console.log(e.target);
    //  setQuote(e.target.value);
    updateFormData(e);
  };

  ///////////Form submission functions////////////////////////////////////////////////////

  const encode = (data) => {
    return Object.keys(data)
      .map(
        (key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key])
      )
      .join("&");
  };
  function handleSubmit(e) {
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({ "form-name": "jobappMark", ...formData }),
    })
      .then(() => console.log("Success"))
      .catch((error) => alert(error));

    e.preventDefault();
  }
  /////////////////////////////////////////////////////////////////////////////////////////

  function updateFormData(e) {
    console.log(formData);
    console.log(e.target.name + "what looking at");
    const field = e.target.name; //get the fileds name so it can be udated in the state(makes it dynamic)
    setFormData((prev) => {
      return {
        ...prev,
        [field]: e.target.value,
      };
    });
  }
  function submitData(e) {
    if (dataSent) return; //so they can't keep pushing the div to send data
    setDataSent(true);
    handleSubmit(e);
    setTimeout(() => {
      setDataSent(false);
    }, 2000);
  }

  function goToNextPage() {
    setPage(page + 1);
  }
  function goBackOne() {
    setPage(page - 1);
  }
  const pageArr = [
    <FirstPage
      goToNextPage={goToNextPage}
      setPage={setPage}
      smallScreen={smallScreen}
      setFormData={setFormData}
      formData={formData}
      updateFormData={updateFormData}
    />,
    <SecondPage
      goToNextPage={goToNextPage}
      goBackOne={goBackOne}
      setPage={setPage}
      smallScreen={smallScreen}
      updateFormData={updateFormData}
    />,
    <ThirdPage
      goToNextPage={goToNextPage}
      goBackOne={goBackOne}
      setPage={setPage}
      smallScreen={smallScreen}
      updateFormData={updateFormData}
    />,
    <FinalPage
      goToNextPage={goToNextPage}
      goBackOne={goBackOne}
      setPage={setPage}
      smallScreen={smallScreen}
      handleSubmit={handleSubmit}
      updateFormData={updateFormData}
    />,
    <SuccessIndicator />,
  ];
  return (
    <Paper
      style={{
        display: "flex",
        flexDirection: "column",
        padding: paperPadding,
      }}
    >
      {pageArr[page]}
      <Grid
        item
        container
        xs={12}
        justifyContent="flex-end"
        style={{ marginTop: 8 }}
        className="subtext"
      >
        {`${page + 1} / ${pageArr.length - 1}`}
      </Grid>
    </Paper>
  );
};

const FirstPage = ({ goToNextPage, formData, setFormData, updateFormData }) => {
  const updateJob = (e) => {
    setJob(e.target.value);
    updateFormData(e);
  };

  const [job, setJob] = useState();

  return (
    <Fragment>
      <InputLabel id="demo-simple-select-helper-label">
        Select a Position
      </InputLabel>
      <Select
        labelId="demo-simple-select-helper-label"
        id="demo-simple-select-helper"
        name="job"
        //value={job}
        label="Select a Position"
        onChange={updateJob}
      >
        <MenuItem value={"operator"} name="job" onChange={updateFormData}>
          Driver
        </MenuItem>
        <MenuItem value={"estimators"} name="job" onChange={updateFormData}>
          Estimators
        </MenuItem>
        <MenuItem value={"finisher"} name="job" onChange={updateFormData}>
          Finisher
        </MenuItem>
        <MenuItem value={"foreman"} name="job" onChange={updateFormData}>
          Foreman
        </MenuItem>
        <MenuItem value={"operator"} name="job" onChange={updateFormData}>
          Operator
        </MenuItem>
      </Select>
      <FormHelperText>
        *Select one and enter others at end of form
      </FormHelperText>
      <Button
        variant="outlined"
        onClick={goToNextPage}
        style={{ width: "50%", alignSelft: "center", marginTop: 24 }}
      >
        <p>Next</p>
      </Button>
    </Fragment>
  );
};

const SecondPage = ({
  goToNextPage,
  goBackOne,
  smallScreen,
  updateFormData,
}) => {
  const updateYoe = (e) => {
    setYoe(e.target.value);
    updateFormData(e);
  };

  const [yoe, setYoe] = useState();

  return (
    <Fragment>
      <InputLabel id="demo-simple-select-helper-label">
        Years of Experience
      </InputLabel>
      <Select
        value={yoe}
        label="Select a Position"
        name="yoe"
        onChange={updateYoe}
      >
        <MenuItem value={"0"} name="yoe">
          0
        </MenuItem>
        <MenuItem value={"1-2"} name="yoe">
          1-2
        </MenuItem>
        <MenuItem value={"3-4"} name="yoe">
          3-4
        </MenuItem>
        <MenuItem value={"5+"} name="yoe">
          5+
        </MenuItem>
      </Select>
      <Grid
        item
        container
        xs={12}
        justifyContent={smallScreen ? "space-between" : "center"}
      >
        <Grid item xs={4}>
          <Button
            variant="outlined"
            onClick={goBackOne}
            style={{
              marginTop: 24,
            }}
          >
            <p>Back</p>
          </Button>
        </Grid>
        <Grid item xs={4}>
          <Button
            variant="outlined"
            onClick={goToNextPage}
            style={{
              marginTop: 24,
            }}
          >
            <p>Next</p>
          </Button>
        </Grid>
      </Grid>
    </Fragment>
  );
};

const ThirdPage = ({
  goToNextPage,
  goBackOne,
  smallScreen,
  updateFormData,
}) => {
  return (
    <Fragment>
      <TextField
        onChange={updateFormData}
        label="Name"
        name="name"
        variant="filled"
      />
      <TextField
        onChange={updateFormData}
        label="Phone"
        name="phone"
        variant="filled"
      />
      <TextField
        onChange={updateFormData}
        label="Email"
        name="email"
        variant="filled"
      />
      <Grid
        item
        container
        xs={12}
        justifyContent={smallScreen ? "space-between" : "center"}
      >
        <Grid item xs={4}>
          <Button
            variant="outlined"
            onClick={goBackOne}
            style={{
              marginTop: 24,
            }}
          >
            <p>Back</p>
          </Button>
        </Grid>
        <Grid item xs={4}>
          <Button
            variant="outlined"
            onClick={goToNextPage}
            style={{
              marginTop: 24,
            }}
          >
            <p>Next</p>
          </Button>
        </Grid>
      </Grid>
    </Fragment>
  );
};

const FinalPage = ({
  goBackOne,
  goToNextPage,
  setPage,
  smallScreen,
  handleSubmit,
  updateFormData,
}) => {
  function submitData(e) {
    goToNextPage();
    handleSubmit(e);
    e.preventDefault();
    setTimeout(() => {
      setPage(0);
    }, 3000);
  }
  return (
    <Fragment>
      <TextField
        label="Type or Paste Resume"
        name="resume"
        variant="outlined"
        multiline={true}
        rows={10}
        onChange={updateFormData}
      />
      <Grid
        item
        container
        xs={12}
        justifyContent={smallScreen ? "space-between" : "center"}
      >
        <Grid item xs={4}>
          <Button
            variant="outlined"
            onClick={goBackOne}
            style={{
              marginTop: 24,
            }}
          >
            <p>Back</p>
          </Button>
        </Grid>
        <Grid item xs={4}>
          <Button
            type="submit"
            onClick={submitData}
            style={{
              marginTop: 24,
              backgroundColor: "#4BB543",
            }}
          >
            <p>Submit</p>
          </Button>
        </Grid>
      </Grid>
    </Fragment>
  );
};

export const SuccessIndicator = () => {
  return (
    <Fragment>
      <svg
        width="198"
        height="245"
        viewBox="0 0 198 245"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style={{ alignSelf: "center" }}
      >
        <path
          d="M72.7488 201.91L20.2461 136.945L43.5936 108.055L72.7488 144.233L154.407 43.0898L177.754 71.9794L72.7488 201.91Z"
          fill="#4BB543"
        />
      </svg>

      <h3 style={{ marginTop: 24, textAlign: "center" }}>
        Succesfuly Submited. We'll be in touch soon.
      </h3>
    </Fragment>
  );
};

export default JobPaper;
