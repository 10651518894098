import backgroundImagePhoto from "./Photos/TuffHammerPhotos/backgroundImagePhoto.jpg";
import driveway from "./Photos/TuffHammerPhotos/driveway.jpg"; //did not want
import driveway2 from "./Photos/TuffHammerPhotos/driveway2.jpg";
import driveway3 from "./Photos/TuffHammerPhotos/driveway3.jpg";
import driveway4 from "./Photos/TuffHammerPhotos/driveway4.jpg";
import driveway5 from "./Photos/TuffHammerPhotos/driveway5.jpg";
import stairs from "./Photos/TuffHammerPhotos/stairs.jpg"; //did not want
import stairs2 from "./Photos/TuffHammerPhotos/stairs2.jpg";
import stairs3 from "./Photos/TuffHammerPhotos/stairs3.jpg";
import stairs4 from "./Photos/TuffHammerPhotos/stairs4.jpg"; //did not want
import other from "./Photos/TuffHammerPhotos/other.jpg";
import other2 from "./Photos/TuffHammerPhotos/other2.jpg";
import other3 from "./Photos/TuffHammerPhotos/other3.jpg";
import other4 from "./Photos/TuffHammerPhotos/other4.jpg";
import other5 from "./Photos/TuffHammerPhotos/other5.jpg";
import other6 from "./Photos/TuffHammerPhotos/other6.jpg";
import other7 from "./Photos/TuffHammerPhotos/other7.jpg"; //did not want
import other8 from "./Photos/TuffHammerPhotos/other8.jpg";
import other9 from "./Photos/TuffHammerPhotos/other9.jpg";
import tuffHammerPics1 from "./Photos/TuffHammerPhotos/tuffHammerPics1.jpeg";
import tuffHammerPics2 from "./Photos/TuffHammerPhotos/tuffHammerPics2.jpeg";
import tuffHammerPics3 from "./Photos/TuffHammerPhotos/tuffHammerPics3.jpeg";
import tuffHammerPics4 from "./Photos/TuffHammerPhotos/tuffHammerPics4.jpeg";
import tuffHammerPics5 from "./Photos/TuffHammerPhotos/tuffHammerPics5.jpeg";
import tuffHammerPics6 from "./Photos/TuffHammerPhotos/tuffHammerPics6.jpeg";
import tuffHammerPics7 from "./Photos/TuffHammerPhotos/tuffHammerPics7.jpeg";
import tuffHammerPics8 from "./Photos/TuffHammerPhotos/tuffHammerPics8.jpeg";
import tuffHammerPics9 from "./Photos/TuffHammerPhotos/tuffHammerPics9.jpeg";
import tuffHammerPics10 from "./Photos/TuffHammerPhotos/tuffHammerPics10.jpeg";
import tuffHammerPics11 from "./Photos/TuffHammerPhotos/tuffHammerPics11.jpeg";
export const drivewayArr = [driveway2, driveway3, driveway5, driveway4];

export const stairsArr = [stairs2, stairs3];

export const othersArr = [
  other,
  other2,
  other3,
  other4,
  other5,
  other6,
  other8,
  other9,
  tuffHammerPics1,
  tuffHammerPics2,
  tuffHammerPics3,
  tuffHammerPics4,
  tuffHammerPics5,
  tuffHammerPics6,
  tuffHammerPics7,
  tuffHammerPics8,
  tuffHammerPics9,
  tuffHammerPics10,
  tuffHammerPics11,
];
